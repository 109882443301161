import { useEffect, useMemo, useRef, useState } from "react";
import {
    ACTIONS_PARAM_NAMES_ENVELOPES,
    ActionsTypesForGuestRoutes,
    EnvelopeActionStatus,
    FIELD_TYPES,
    FINISH_LATER_BTN_CHECK,
    envelopeActionEnum,
    envelopeHeaderKey,
    normalRoutesConstant
} from "util/util.constant";
// import "./ip-form.less";
import { Button, message } from "antd";
import moment from "moment";
import { useQueryEnvelopebyPassedRoute } from "queries/envelope.queries";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { UtilService } from "util/util.service";
import { signData } from "../../redux/tabSlice";
import CustomSignatureModal from "./CustomSignatureModal";
import DraggableSignatureBox from "./DraggableSignatureBox";
import HTMLDropWrapper from "./HTMLDropWrapper";
import PrintSignatureField from "./PrintSignatureField";
import { SIGNATURE_MODAL } from "./SignatureModal";

function HTMLEnvelopeFormView() {
    const getSigningLocalStorageData = useSelector(signData);
    const oneSecond = 1000;
    const oneMinute = oneSecond * 60;
    const autoSaveTime = process.env.REACT_APP_FORM_AUTOSAVE_TIME * oneMinute;
    const parser = new DOMParser();
    const pdfRef = useRef();
    const [cssSrc, setCssSrc] = useState(null);
    const [scriptSrc, setScriptSrc] = useState("ip-form/ip-form-script.js");
    const [fields, setFields] = useState([]);
    const [signaturePopup, setSignaturePopup] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(false);
    const customFinishBtnLoader = useRef(false);
    const updateCloneAction = useRef(true);

    const { mutateAsync: createEnvelopeByPassedRouteMutateQuery, isLoading: updateEnvelopeByPassedLoading } =
        useQueryEnvelopebyPassedRoute();

    const wide = useWindowWide(600);

    const isDraftEnvelope = useRef(null);

    const {
        refresh_token,
        x_envelope,
        env: envelopeIdParam,
        action: actionParam,
        reviewer,
        client_api_key = "",
        send_email = false,
        restrictions = "",
        is_document_edit,
        webhookData,
        showButtons,
        data
    } = useMemo(() => UtilService.urlParameters(), [window.location.search]);

    const decodedWebhookData = webhookData && JSON.parse(atob(webhookData));
    const review = reviewer && JSON.parse(reviewer);
    const [signatureField, setSignatureField] = useState(null);
    const queryNameField = UtilService.base64toArray(data);

    const convertArrayOfQueryToObject = useMemo(
        () => queryNameField?.reduce((obj, item) => ((obj[item.queryName] = item.value), obj), {}),
        []
    );

    const signingData = useMemo(() => {
        return getSigningLocalStorageData !== null || getSigningLocalStorageData !== ""
            ? getSigningLocalStorageData
            : false;
    }, [getSigningLocalStorageData]);
    const targetElementRef = useRef(null);
    // When envelope is creating it give template data
    const { template: { attributes: templateAttribute, id: templateId, name: templateName } = {} } = signingData || {};
    // when envelope is created it give envelope data
    const { envelope: { attributes: envelopeAttribute, id: envelopId, name: envelopeName } = {} } = signingData || {};

    const { templateDetail, htmlType: templateHtmlType, type: templateType } = templateAttribute || {};
    const { envelopeDetail, template: envelopeTemplate } = envelopeAttribute || {};

    const { htmlType: envelopeHtmlType, type: envelopeType } = envelopeTemplate?.attributes || {};

    const { tabs: templateTabs } = templateDetail || {};
    const { tabs: envelopeTabs } = envelopeDetail || {};

    const tabs = templateTabs || envelopeTabs;
    const id = templateId || envelopId;
    const name = templateName || envelopeName;
    const htmlType = templateHtmlType || envelopeHtmlType || {};
    const type = templateType || envelopeType;

    const checkUserEnvelopeGeneratedFormView = useMemo(
        () => signingData && Boolean(refresh_token) && Boolean(x_envelope),
        [signingData, refresh_token, x_envelope]
    );
    const timeoutIdRef = useRef(null);

    useEffect(() => {
        // Function to be called when the target element changes
        const handleMutation = () => {
            // Do something when the target element changes
            clearTimeout(timeoutIdRef.current);

            // isAutoSaveInProgress.current = true;
            timeoutIdRef.current = setTimeout(() => {
                onFormFinish("", true, true);
            }, autoSaveTime);
        };

        // Options for the MutationObserver (configure as needed)
        const observerOptions = {
            attributes: true, // Set to true if you want to observe attribute changes
            childList: true, // Set to true if you want to observe child elements changes
            subtree: true // Set to true if you want to observe changes in the whole subtree
        };
        handleMutation();

        // Create a new MutationObserver with the callback function and options
        const observer = new MutationObserver(() => {
            handleMutation();
        });

        // Start observing the target element
        if (targetElementRef.current) {
            observer.observe(targetElementRef.current, observerOptions);
        }

        // Clean up the observer when the component is unmounted
        return () => {
            clearTimeout(timeoutIdRef.current);
            observer.disconnect();
        };
    }, []);
    useEffect(() => {
        document.body.style.background = "grey";
        const signatureModal = parser.parseFromString(SIGNATURE_MODAL, "text/html");
        const modal = signatureModal.getElementById("signatureModal");
        const modalBackdrop = signatureModal.getElementById("modalBackdrop");
        document.body.appendChild(modalBackdrop);
        document.body.appendChild(modal);
        if (htmlType === "CNA") {
            setCssSrc("cna-form/style.css");
            setScriptSrc("cna-form/cna-form-script.js");
        } else {
            setCssSrc("ip-form/ip-form.css");
            // setScriptSrc("ip-form/ip-form-script.js");
        }
        const signatureFields =
            tabs &&
            tabs.filter((tab) => {
                if (tab.type === "signature") {
                    return tab;
                }
            });
        setFields(signatureFields);
        // console.log("signatureFields: ", signatureFields);
    }, []);

    const autofillFields = ["lastNameFirstName", "careId", "componentCode", "ipcStarts", "ipcEnds", "dateOfBirth"];

    const dateFields = ["ipcStarts", "ipcEnds", "dateOfBirth"];

    useEffect(() => {
        if (convertArrayOfQueryToObject) {
            autofillFields.forEach((field) => {
                const element = document.getElementsByName(field)[0];
                if (element) {
                    if (dateFields.includes(field)) {
                        element.setAttribute(
                            "value",
                            convertArrayOfQueryToObject[field]
                                ? moment(convertArrayOfQueryToObject[field]).format("YYYY-MM-DD")
                                : ""
                        );
                    } else {
                        element.setAttribute(
                            "value",
                            convertArrayOfQueryToObject[field] ? convertArrayOfQueryToObject[field] : ""
                        );
                    }
                }
            });
            const currentDateElement = document.getElementsByName("currentDate")[0];
            if (currentDateElement) {
                currentDateElement.setAttribute("value", moment().format("YYYY-MM-DD"));
            }
        }
    }, []);
    const onFormFinish = async (event, btnStatus = false, isAutoSave = false) => {
        console.log("customFinishBtnLoader", customFinishBtnLoader.current);
        if (customFinishBtnLoader.current) return;
        if (isAutoSave) {
            clearTimeout(timeoutIdRef.current);
        }
        if (event) {
            event?.preventDefault();
        }
        const pages = document.getElementsByClassName("page");

        let tabs = [];

        for (let i = 0; i < pages.length; i++) {
            tabs.push({
                type: "html",
                pageNumber: i + 1,
                value: pages[i].outerHTML
            });
        }
        tabs = [...tabs, ...fields];

        customFinishBtnLoader.current = true;
        updateEnvelopeCall(tabs, btnStatus, isAutoSave);
    };

    // const onDownload = () => {
    // const input = pdfRef.current;
    // html2canvas(input).then((canvas) => {
    //     const imgData = canvas.toDataURL("image/png");
    //     const pdf = new jsPDF("p", "mm", "a4", true);
    //     const pdfWidth = pdf.internal.pageSize.getWidth();
    //     const pdfHeight = pdf.internal.pageSize.getHeight();
    //     const imgWidth = canvas.width;
    //     const imgHeight = canvas.height;
    //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
    //     const imgY = 30;
    //     pdf.addImage(imgData, "PNG", imgX, imgY, imgWidth * ratio, imgHeight * ratio);
    //     pdf.save("form.pdf");
    // });
    // window.print();
    // };

    const updateEnvelopeCall = (tabsFields, isFinishLaterBtn = false, isAutoSave = false) => {
        if (showButtons == 1) {
            return;
        }

        let findNameField;
        let findEmailField;

        if (data) {
            const queryNameFields = UtilService.base64toArray(data);
            findNameField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerName
            )?.value;
            findEmailField = queryNameFields.find(
                (singleField) => singleField.queryName === FIELD_TYPES.signerEmail
            )?.value;
        }

        if (checkUserEnvelopeGeneratedFormView) {
            const headers = [
                {
                    headerKey: envelopeHeaderKey.refreshToken,
                    headerValue: refresh_token
                },
                {
                    headerKey: envelopeHeaderKey.xEnvelope,
                    headerValue: isDraftEnvelope.current?.x_envelope ?? x_envelope
                },
                {
                    headerKey: envelopeHeaderKey.clientApiKey,
                    headerValue: client_api_key
                }
            ];
            const payload = {
                tabs: tabsFields,
                name: `${name ?? ""} ${id}`,
                action: envelopeActionEnum.envelopeFinished,
                webhook_data: decodedWebhookData,
                envelopeAction: isDraftEnvelope?.current?.check
                    ? ActionsTypesForGuestRoutes.UPDATE_ENVELOPE
                    : ActionsTypesForGuestRoutes.CREATE_ENVELOPE,
                email: ""
            };
            if (isFinishLaterBtn) {
                payload.action = envelopeActionEnum.EnvelopeFinishLater;
            }

            if (Boolean(envelopeIdParam) && Boolean(actionParam)) {
                payload.name = signingData?.attributes?.name;
                payload.email = signingData?.attributes?.email;

                if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE && updateCloneAction.current) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.CLONE_ENVELOPE;
                    updateCloneAction.current = false;
                } else if (actionParam === ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE) {
                    payload.envelopeAction = ACTIONS_PARAM_NAMES_ENVELOPES.UPDATE_ENVELOPE;
                }
            }

            if (findNameField) {
                payload.name = findNameField;
            }
            if (findEmailField) {
                payload.email = findEmailField;
            }

            if (isAutoSave) {
                payload.statusAction = EnvelopeActionStatus.DRAFT;
                isDraftEnvelope.current = {
                    ...isDraftEnvelope.current,
                    check: true
                };
            }

            try {
                setButtonDisable(true);

                createEnvelopeByPassedRouteMutateQuery(
                    { payload, headers },
                    {
                        onSuccess: (data) => {
                            createEnvelopeSuccess(data, isAutoSave);

                            const authorizeToken = data?.attributes?.authorizeToken;

                            if (isAutoSave && Boolean(authorizeToken)) {
                                isDraftEnvelope.current = {
                                    ...isDraftEnvelope.current,
                                    x_envelope: authorizeToken
                                };
                            }
                        },
                        onSettled: (data) => {
                            customFinishBtnLoader.current = false;
                        }
                    }
                );
            } catch (error) {
                console.log("error", error);
            } finally {
                setButtonDisable(false);
            }
        }
    };

    const createEnvelopeSuccess = (data, isAutoSave = false) => {
        message.success(`envelope is  ${isAutoSave ? "auto " : ""}saved successfully!`);

        if (!isAutoSave) {
            const connectReturnUrl = data?.attributes?.connectReturnUrl;
            const addParams = `?env=${data?.attributes?.identity_key}`;
            const connectReturnUrlTwo = `${connectReturnUrl}${addParams}`;
            const connectReturnUrlThree = `${normalRoutesConstant.ThankYou}${addParams}`;
            if (Boolean(send_email)) {
                window.location.href = connectReturnUrlThree;
            } else {
                const exactRedirectUrl = connectReturnUrl ? connectReturnUrlTwo : connectReturnUrlThree;
                window.location.href = exactRedirectUrl;
            }
        }
    };

    const showSignatureModal = (event, singleField) => {
        event.preventDefault();
        setSignaturePopup(true);
        setSignatureField(singleField);
        // getRecentSignatures();
    };

    const addSignature = (signatureValue) => {
        const currentDate = moment().format("MM-DD-yyyy h:mm a");
        const updateField = fields.map((field) => {
            if (field.id === signatureField.id) {
                return { ...field, value: signatureValue, currentDateTime: currentDate };
            }
            return field;
        });
        setFields(updateField);
    };

    const trackPositions = (data, singleFieldParam) => {
        const updateField = fields.map((field) => {
            if (field.id === singleFieldParam.id) {
                return { ...field, x: data?.x, y: data?.y + window.scrollY };
            }
            return field;
        });
        setFields(updateField);
        // dispatch(
        //     updateField({
        //         id: singleFieldParam.id,
        //         x: data?.x,
        //         y: data?.y
        //     })
        // );
    };

    return (
        <>
            <div>
                <Helmet>
                    <script
                        src={`/statichtmlPages/${scriptSrc}`}
                        async
                        type="application/javascript"
                        // id="ip-form-script"
                    />

                    <script
                        src="/html-signature-modal-script.js"
                        async
                        type="application/javascript"
                        id="signature-modal-script"
                    />
                    <link rel="stylesheet" href={`/statichtmlPages/${cssSrc}`} />
                </Helmet>

                {/* {showButtons == 1 && (
                    <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem", margin: "12px 0px" }}>
                        <Button type="primary" onClick={onDownload} className="common-button envelope-btn print-button">
                            Download
                        </Button>
                    </div>
                )} */}

                {showButtons != 1 && (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: wide ? "row" : "column",
                            gap: "1rem",
                            margin: "12px auto",
                            textAlign: "center",
                            maxWidth: "900px",
                            position: "sticky",
                            top: 0,
                            zIndex: 10,
                            padding: "9px 12px",
                            background: "rgba(255,255,255,0.4)",
                            borderRadius: "2px",
                            boxShadow: "0 4px 30px rgba(0,0,0,0.1)",
                            backdropFilter: "blur(5px)",
                            border: "1px solid rgba(255,255,255,0.3)",
                            alignItems: "center"
                        }}
                    >
                        <div
                            style={{
                                flex: 1,
                                color: "black",
                                background: "#fffbe6",
                                padding: "8px 12px",
                                borderRadius: 5,
                                border: "1px solid #ffe58f"
                            }}
                        >
                            *if your are working with large data please make sure that you save occasionally
                        </div>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                type="primary"
                                onClick={onFormFinish}
                                disabled={buttonDisable}
                                className="common-button envelope-btn"
                                loading={customFinishBtnLoader.current || updateEnvelopeByPassedLoading}
                            >
                                Finish
                            </Button>

                            {!review && !Boolean(send_email) && (
                                <>
                                    {restrictions !== "1" && (
                                        <Button
                                            type="primary"
                                            onClick={(event) => onFormFinish(event, FINISH_LATER_BTN_CHECK)}
                                            className="common-button envelope-btn envelope-btn-finish-later"
                                            disabled={
                                                customFinishBtnLoader.current ||
                                                updateEnvelopeByPassedLoading ||
                                                buttonDisable
                                            }
                                        >
                                            Save Work
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                )}
                <div ref={pdfRef}>
                    <DndProvider backend={HTML5Backend}>
                        {send_email && (
                            <div style={{ position: "fixed" }}>
                                <DraggableSignatureBox setFields={setFields} />
                            </div>
                        )}
                        <div
                            style={{
                                width: "900px",
                                margin: "0 auto",
                                ...(showButtons == 1 && { pointerEvents: "none" })
                            }}
                        >
                            <HTMLDropWrapper setFields={setFields}>
                                <div
                                    style={{ width: "900px", margin: "0 auto" }}
                                    id="page-container"
                                    className={`${
                                        Boolean(is_document_edit == "" || is_document_edit == "false")
                                            ? "input-disabled"
                                            : ""
                                    }`}
                                >
                                    {fields?.map((field) => {
                                        return (
                                            <PrintSignatureField
                                                field={field}
                                                key={field.id}
                                                showSignatureModal={showSignatureModal}
                                                trackPositions={trackPositions}
                                                setFields={setFields}
                                                fields={fields}
                                            />
                                        );
                                    })}
                                    <div ref={targetElementRef}>
                                        {tabs &&
                                            type === "HTML" &&
                                            tabs.map((tab, index) => {
                                                if (tab?.type === "html") {
                                                    return (
                                                        <div
                                                            // ref={targetElementRef}
                                                            key={index}
                                                            dangerouslySetInnerHTML={{
                                                                __html: parser.parseFromString(tab.value, "text/html")
                                                                    .documentElement.outerHTML
                                                            }}
                                                        />
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            </HTMLDropWrapper>
                        </div>
                    </DndProvider>
                </div>
            </div>
            <CustomSignatureModal
                setSignaturePopup={setSignaturePopup}
                signaturePopup={signaturePopup}
                addSignature={addSignature}
            />
        </>
    );
}

export default HTMLEnvelopeFormView;

const useWindowWide = (size) => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [setWidth]);

    return width > size;
};

